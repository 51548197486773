import React from 'react';
import logo from './logo.svg';
import FadeIn from 'react-fade-in';
import './App.css';

function App() {
    return (
        <div className='App'>
            <header className='App-header'>

                <img src={logo} className='App-logo' alt='logo' />

                <h1 id='title'>
                    welcome to <a
                        className='App-link App-link-title'
                        href=''
                        //   target='_blank'
                        rel='noopener noreferrer'
                    >
                        franciscamoya<span>.</span>dev
                    </a>
                </h1>
                <p id='bio'>software engineer, honorary Chicagoan and deep dish enthusiast 🍕</p>

                <FadeIn>
                    <div>
                        <p>
                            currently working on:
                        </p>
                        <div className='linkHover'>
                            <a
                                className='App-link'
                                href='https://app.primeprotocol.xyz/'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                app.primeprotocol.xyz
                            </a>
                        </div>
                        <p className='description'>web3, finance |  descentralized borrowing platform</p>
                        <p className='description'>backend and frontend</p>
                    </div>

                    <div>
                        <p>past work:</p>
                        <div className='linkHover'>
                            <a
                                className='App-link'
                                href='https://learn2strut.com/'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                learn2strut.com
                            </a>
                        </div>
                        <p className='description'>edtech |  platform to teach social/emotional skills to students</p>
                        <p className='description'>UX/UI, frontend</p>
                    </div>

                    <div>
                        <p>projects:</p>
                        <div className='linkHover'>
                            <a
                                className='App-link'
                                href='https://expo.dev/@fmoyaj/Bubble'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                bubble
                            </a>
                        </div>
                        <p className='description'>React Native app |  hub for events at the Wellesley College campus</p>
                        <p className='description'>UX/UI, fullstack</p>

                        <div className='linkHover'>
                            <a
                                className='App-link'
                                href='https://github.com/fmoyaj/aucvar'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                aucvar, an R package
                            </a>
                        </div>
                        <p className='description'>statistics, machine learning |  methods to estimate the AUC variance</p>
                    </div>

                    <div>
                        <p>
                            let's connect!
                        </p>
                        <div className='linkHover'>
                            <a
                                className='App-link'
                                href='https://github.com/fmoyaj'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                github
                            </a>
                        </div>
                        <div className='linkHover'>
                            <a
                                className='App-link'
                                href='https://www.linkedin.com/in/fmoyaj/'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                linkedin
                            </a>
                        </div>
                    </div>
                </FadeIn>
            </header>
        </div>
    );
}

export default App;
